import { message } from 'antd';
import axios from 'axios';
import { base } from '../config';

const api = axios.create({
  baseURL: base,
});

const createAxiosResponseInterceptor = () => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        message.error(error.message || 'Erro na requisição');
        return Promise.reject(error);
      }
      return null;
    },
  );
};

createAxiosResponseInterceptor();

export default api;
