import React from 'react';

function Header() {
  return (
    <div className="row pt-5 pb-2">

      <a href="/" className="logo col-12 mb-3" target="_self">
        <img src="/images/logo.svg" alt="" className="img-fluid" width="120" />
      </a>
      <div className="col-12 mt-3">
        <div className="alert alert-success">
          <h5>Não existem incidentes reportados</h5>
        </div>
      </div>
    </div>
  );
}

export default Header;
