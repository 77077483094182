import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from './routes';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import history from './helpers/history';
import store from './store';

function App() {
  return (
    <Provider store={store()}>
      <Router history={history}>
        <div className="container">
          <Header />
          <Routes />
        </div>
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;
