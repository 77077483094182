import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  get: () => ({
    type: actionsTypes.STATUS_ENDPOINTS,
    payload: api.get('/'),
  }),
};

export default actions;
