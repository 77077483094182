const config = {};

const production = process.env.REACT_APP_STAGE === 'production';
const baseProduction = 'https://api.botmax.com.br/status';
const baseDev = 'http://localhost:3000/status';

config.base = production ? baseProduction : baseDev;
config.production = production;

module.exports = config;
