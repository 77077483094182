import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';

import reducers from './reducers';

const store = () => {
  const create = applyMiddleware(ReduxPromise)(createStore);
  return create(reducers);
};

export default store;
