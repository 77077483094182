import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Tag } from 'antd';
import actionStatus from '../../actions/actionStatus';

function sortLocale(a, b) {
  return a.localeCompare(b, { sensitivity: 'base' });
}

function Home() {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.reducerStatus.items);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(actionStatus.get());
    setLoading(false);
  }, []);

  const statusOptions = {
    disabled: {
      backgroundColor: '#fff',
      name: 'Desabilitado',
    },
    development: {
      backgroundColor: '#d4e2ed',
      name: 'Em Desenvolvimento',
    },
    operational: {
      backgroundColor: '#d4edda',
      name: 'Operacional',
    },
    degraded: {
      backgroundColor: '#edd4d8',
      name: 'Degradado',
    },
    deprecated: {
      backgroundColor: '#e0e0e0',
      name: 'Descontinuado',
    },
  };

  useEffect(() => {
    if (items.length) {
      setLoading(false);
      const status = {};
      items.map((item) => {
        if (!status[item.platform]) {
          status[item.platform] = {}
        }
        if (!status[item.platform][item.domain]) {
          status[item.platform][item.domain] = {}
        }
        status[item.platform][item.domain][item.source] = item.status;
        return item;
      })
      setStatus(status);
    }
  }, [items]);

  return (
    <Spin spinning={loading}>
      <div className="row pt-1 pb-1   pl-3">
        <ul className="list-group col-12">
          {
            Object.keys(status).sort(sortLocale).map((platform) => (
              <li className="list-group-item">
                <h5 className="d-block font-weight-bold">{platform}</h5>
                {Object.keys(status[platform]).sort(sortLocale).map((source) => (
                  <div>

                  <h6 className="d-block font-weight-bold">{source}</h6>
                  <div className="row d-inline-flex w-100">
                    <span className="col-12">{
                      Object.keys(status[platform][source]).sort(sortLocale).map((domain) => {
                        const { backgroundColor } = statusOptions[status[platform][source][domain]]
                        return <Tag style={{ backgroundColor }} className="mr-2 mb-2">{domain}</Tag>
                      })
                    }
                    </span>
                  </div>
                  </div>
                ))}
              </li>
            ))
          }
        </ul>
      </div>
    </Spin>
  );
}

export default Home;
