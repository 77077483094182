import React from 'react';

function Footer() {
  return (
    <div className="pt-5 pb-5 bg-color-1 mt-3">
      <div className="container">
        <div className="row">
          <div className="col-6 text-white">
            © 2021-
            {new Date().getFullYear()}
            {' '}
            Botmax Serviços em Tecnologia da Informação Ltda
          </div>
          <div className="col-6 text-right">
            <a href="https://www.facebook.com/botmaxbr" target="_blank" rel="noreferrer" className="mr-2">
              <i className="fab fa-facebook-square fa-2x text-white" />
            </a>
            <a href="https://www.instagram.com/botmaxbr" target="_blank" rel="noreferrer" className="mr-2">
              <i className="fab fa-instagram-square fa-2x text-white" />
            </a>
            <a href="https://github.com/botmaxbr" target="_blank" rel="noreferrer">
              <i className="fab fa-github-square fa-2x text-white" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
